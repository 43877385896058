import { matchPath } from "react-router-dom";
// signup
import GenericSignup from "../../components/GenericSignup";
// shop
import GenericDashboard from "../../components/GenericDashboard";
import GenericShop from "../../components/GenericShop";
import GenericShopCart from "../../components/GenericShopCart";
import GenericShopCartCheckout from "../../components/GenericShopCartCheckout";
import GenericShopPurchase from "../../components/GenericShopPurchase";
import GenericMyShopCarts from "../../components/GenericMyShopCarts";
import GenericPage from "../../components/GenericPage";
import GenericPaymentLink from "../../components/GenericPaymentLink";
import GenericSaleLink from "../../components/GenericSaleLink";
import Home from "../../components/Home";
import store from "../../store";

// eslint-disable-next-line no-undef
export const ENVIRONMENT = typeof WEBPACK_ENVIRONMENT === "undefined" ? process.env.NODE_ENV : WEBPACK_ENVIRONMENT;

export const PageComponents = {};
PageComponents.PrivacyPolicy = require( "./components/PrivacyPolicy" ).default;
PageComponents.ServiceConditions = require( "./components/ServiceConditions" ).default;
PageComponents.CookiesPolicy = require( "./components/CookiesPolicy" ).default;

export const getUrlBase = ( env ) => {
    if ( env === "development" ) {
        return "http://localhost:2048";
    }
    if ( env === "staging" ) {
        return "https://staging.pichichus.com";
    }
    return "https://www.pichichus.com";
};

export const company = {
    name: "Pichichus",
    legalName: "Daniela Soledad Diaz",
    domain: "pichichus.com",
    lopdFullName: "Daniela Soledad Diaz",
    lopdNIF: "x8896173A",
    lopdEmail: "daniela@pichichus.com",
    lopdPostalAddress: "Miquel Arcas 1, Local 5",
    lopdPhone: "673086547",
    phoneLink: "tel:+34673086547",
    contactAddress: "Miquel Arcas 1, Local 5, 07010 Palma, Illes Balears",
    contactTimeInfo: "Lunes Martes y Miércoles de 10:00 a 18:00<br/>Jueves y Viernes de 10:00 a 14:00 y de 16:00 a 20:00<br/>sábados y domingos cerrado",
    contactBusInfo: "",
    facebookPageURL: "https://www.facebook.com/PichichusMallorca/",
    instagramPageURL: "https://www.instagram.com/pichichusmallorca/",
    whatsappContactURL: "https://wa.me/34673086547",
    googleFontsTag: "<link href=\"https://fonts.googleapis.com/css?family=Poiret+One\" rel=\"stylesheet\" />",
    adobeFontsTag: "<link rel=\"stylesheet\" href=\"https://use.typekit.net/gkf0vig.css\">",
    googleAnalyticsTrackingID: "UA-143522464-2",
    googleTagManagerId: "G-1WEKC66ZKL",
    facebookAPPID: null,
    contactEmail: "daniela@pichichus.com",
    managerURLRelative: "/pichichusonline",
    baseURL: getUrlBase( ENVIRONMENT ),
    googleSignInClientID: null,
    facebookPixelId: "699030780831156",
    ZohoChatSalesIQCode: null,
    smartlookCode: null,
    cliengoScriptUrl: null,
    footerTermsConditions: true,
    RGPDCommercialNotificationsCheck: true,
    RGPDSignupCommercialNotificationsCheck: true,
    RGPDStripeInfo: false,
    RGPDAWSInfo: true,
    RGPDMessagesReady: true,
    modSignupEnabled: true,
    uiNavigationHideShopSidebar: true,
    shopCart: true, // change later
    hideAddToCart: false, // change later
    dashboardModules: [ "welcome", "questions" ],
    dashboardURLRelative: "/dashboard",
    footerType: "columns",
    googleMapsEmbed: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6149.477403595889!2d2.6506390000000004!3d39.588039!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa815f3895750ffc9!2sPichichus%3A%20Peluqueria%20Canina%20en%20Mallorca!5e0!3m2!1ses!2ses!4v1669597032375!5m2!1ses!2ses",
    stopBookingUnavailable: true
};

export const config = {
    reCaptchaSiteKey: "6LeqmKoUAAAAAPBWBD8aQmVM9XIc4qNQgsjGHdFJ"
};

export const stripe = null;
export const pagantis = {
    enabled: false
};
export const redsys = {
    bizum: false,
    redsysSaveCard: true
};
export const NavBar = {
    type: "offcanvas",
    logoHeight: 72,
    showShopCategories: true,
    showShopSearch: true, // change later
    searchButtonIcon: true,
    hideGiftCards: true, // change later
    hideServices: true, // change later
    showShopCategoriesMainGroups: true,
    hideShopSearchInPages: [],
    hideSpecialPrices: true,
    topNavBar: {
        enabled: true, // change later
        cartLink: "/carrito",
        cartText: "Mi cesta"
    },
    customMenuItems: [
        {
            id: "inicio",
            menuText: "Inicio",
            relativeUrl: "/",
            menuItems: null
        },
        {
            id: "curso-peluqueria-canina",
            menuText: "Curso de Peluquería Canina",
            relativeUrl: "/p/curso-peluqueria-canina",
            afterCategories: true
        }
    ]
};

export const all = [
    {
        path: "(/|/peluqueria-canina-palma-mallorca/)",
        relativeUrl: "/peluqueria-canina-palma-mallorca/",
        url: "https://www.pichichus.com",
        component: Home,
        exact: true,
        id: "home",
        menuText: "Inicio",
        title: "Inicio",
        subTitle: "Peluqueria para mascotas en Palma de Mallorca",
        breadcrumb: [],
        menuHidden: true,
        seoTitle: "Peluqueria para mascotas en Palma de Mallorca",
        seoDescription: "Peluqueria para mascotas en Palma de Mallorca. Servicios de peluquería para perros, gatos y conejos en Palma de Mallorca.",
        seoKeywords: "Peluqueria para mascotas en Palma de Mallorca, Peluquería para perros, gatos y conejos en Palma de Mallorca, Peluquería Canina, Estilista Canina, Cortes de pelo para perritos, Cortes de pelo para mascotas, Tienda para Mascotas, Peluquería para Mascotas, Peluqueria Felina",
        seoAuthor: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        seoDefaultAlt: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        embeds: [],
        gallery: {
            id: "generic"
        }
    },
    {
        path: "/salon-mascotas-mallorca/politica-privacidad",
        relativeUrl: "/salon-mascotas-mallorca/politica-privacidad",
        url: "https://www.pichichus.com/salon-mascotas-mallorca/politica-privacidad",
        exact: true,
        component: GenericPage,
        childComponentFile: "PrivacyPolicy",
        id: "privacy-policy",
        menuText: "Política de privacidad",
        menuHidden: true,
        title: "Política de privacidad",
        subTitle: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        breadcrumb: [ "home", "privacy-policy" ],
        seoTitle: "Política de privacidad",
        seoDescription: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        seoKeywords: "El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes actualizarlos, gestionarlos, exportarlos y eliminarlos.",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "/salon-mascotas-mallorca/politica-cookies",
        relativeUrl: "/salon-mascotas-mallorca/politica-cookies",
        url: "https://www.pichichus.com/salon-mascotas-mallorca/politica-cookies",
        exact: true,
        component: GenericPage,
        childComponentFile: "CookiesPolicy",
        id: "cookies-policy",
        menuText: "Política de Cookies",
        menuHidden: true,
        title: "Política de Cookies",
        subTitle: "",
        breadcrumb: [ "home", "cookies-policy" ],
        seoTitle: "Política de Cookies",
        seoDescription: "Política de Cookies",
        seoKeywords: "Política de Cookies",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "/politica-contratacion",
        relativeUrl: "/politica-contratacion",
        url: "https://www.pichichus.com/politica-contratacion",
        exact: true,
        component: GenericPage,
        childComponentFile: "ServiceConditions",
        id: "terms-conditions",
        menuText: "Política de Contratación",
        menuTextEn: "Terms and General Conditions",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: null,
        seoTitle: "Condiciones de servicio",
        seoDescription: "",
        seoKeywords: "",
        embeds: [],
        gallery: {},
        seoAuthor: "",
        seoDefaultAlt: ""
    },
    {
        path: "(/p/.*)",
        relativeUrl: "/p",
        url: "https://www.pichichus.com/p",
        component: GenericPage,
        id: "pages",
        menuText: "",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: null,
        embeds: [],
        seoTitle: "Peluqueria para mascotas en Palma de Mallorca",
        seoDescription: "Peluqueria para mascotas en Palma de Mallorca. Servicios de peluquería para perros, gatos y conejos en Palma de Mallorca.",
        seoKeywords: "Peluqueria para mascotas en Palma de Mallorca, Peluquería para perros, gatos y conejos en Palma de Mallorca, Peluquería Canina, Estilista Canina, Cortes de pelo para perritos, Cortes de pelo para mascotas, Tienda para Mascotas, Peluquería para Mascotas, Peluqueria Felina",
        seoAuthor: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        seoDefaultAlt: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada"
    },
    {
        path: "(/acceder)",
        relativeUrl: "/acceder",
        url: "https://www.pichichus.com/acceder",
        exact: true,
        component: GenericSignup,
        topDynamicContent: null,
        id: "login",
        menuText: "Mi cuenta",
        menuTextEn: null,
        menuHidden: true,
        title: null,
        subTitle: null,
        breadcrumb: null,
        seoTitle: "Peluqueria para mascotas en Palma de Mallorca",
        seoDescription: "Peluqueria para mascotas en Palma de Mallorca. Servicios de peluquería para perros, gatos y conejos en Palma de Mallorca.",
        seoKeywords: "Peluqueria para mascotas en Palma de Mallorca, Peluquería para perros, gatos y conejos en Palma de Mallorca, Peluquería Canina, Estilista Canina, Cortes de pelo para perritos, Cortes de pelo para mascotas, Tienda para Mascotas, Peluquería para Mascotas, Peluqueria Felina",
        seoAuthor: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        seoDefaultAlt: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        embeds: [],
        gallery: {}
    },
    {
        path: "(/tienda.*)",
        relativeUrl: "/tienda",
        url: "https://www.pichichus.com/tienda",
        exact: true,
        active: company.shopCart,
        component: GenericShop,
        childComponentFile: "Shop",
        id: "shop",
        menuText: "TIENDA",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: null,
        seoTitle: "Tienda Online de Pichichus",
        seoDescription: "Peluqueria para mascotas en Palma de Mallorca. Servicios de peluquería para perros, gatos y conejos en Palma de Mallorca.",
        seoKeywords: "Peluqueria para mascotas en Palma de Mallorca, Peluquería para perros, gatos y conejos en Palma de Mallorca, Peluquería Canina, Estilista Canina, Cortes de pelo para perritos, Cortes de pelo para mascotas, Tienda para Mascotas, Peluquería para Mascotas, Peluqueria Felina",
        seoAuthor: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        seoDefaultAlt: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        embeds: [],
        gallery: {}
    },
    {
        path: "(/carrito)",
        relativeUrl: "/carrito",
        url: "https://www.pichichus.com/carrito",
        exact: true,
        component: GenericShopCart,
        childComponentFile: "ShopCart",
        id: "shop-cart",
        menuText: "Carrito",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: null,
        embeds: [],
        gallery: {},
        seoTitle: "Tienda Pichichus: Carrito",
        seoDescription: "Peluqueria para mascotas en Palma de Mallorca. Servicios de peluquería para perros, gatos y conejos en Palma de Mallorca.",
        seoKeywords: "Peluqueria para mascotas en Palma de Mallorca, Peluquería para perros, gatos y conejos en Palma de Mallorca, Peluquería Canina, Estilista Canina, Cortes de pelo para perritos, Cortes de pelo para mascotas, Tienda para Mascotas, Peluquería para Mascotas, Peluqueria Felina",
        seoAuthor: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        seoDefaultAlt: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada"
    },
    {
        path: "(/compra/detalle.*)",
        relativeUrl: "/compra/detalle",
        url: "https://www.pichichus.com/compra/detalle",
        exact: true,
        component: GenericShopPurchase,
        id: "shop-purchase",
        menuText: "Detalle de Compra",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: null,
        seoTitle: "Tienda Pichichus: Información de item",
        seoDescription: "Peluqueria para mascotas en Palma de Mallorca. Servicios de peluquería para perros, gatos y conejos en Palma de Mallorca.",
        seoKeywords: "Peluqueria para mascotas en Palma de Mallorca, Peluquería para perros, gatos y conejos en Palma de Mallorca, Peluquería Canina, Estilista Canina, Cortes de pelo para perritos, Cortes de pelo para mascotas, Tienda para Mascotas, Peluquería para Mascotas, Peluqueria Felina",
        seoAuthor: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        seoDefaultAlt: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        embeds: [],
        gallery: {}
    },
    {
        path: "(/carrito/checkout)",
        relativeUrl: "/carrito/checkout",
        url: "https://www.pichichus.com/carrito/checkout",
        exact: true,
        component: GenericShopCartCheckout,
        childComponentFile: "ShopCartCheckout",
        id: "shop-cart-checkout",
        menuText: "Carrito Checkout",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: null,
        seoTitle: "Tienda Pichichus: Pasar por caja",
        seoDescription: "Peluqueria para mascotas en Palma de Mallorca. Servicios de peluquería para perros, gatos y conejos en Palma de Mallorca.",
        seoKeywords: "Peluqueria para mascotas en Palma de Mallorca, Peluquería para perros, gatos y conejos en Palma de Mallorca, Peluquería Canina, Estilista Canina, Cortes de pelo para perritos, Cortes de pelo para mascotas, Tienda para Mascotas, Peluquería para Mascotas, Peluqueria Felina",
        seoAuthor: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        seoDefaultAlt: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        embeds: [],
        gallery: {}
    },
    {
        path: "(/dashboard)",
        relativeUrl: "/dashboard",
        url: "https://www.pichichus.com/dashboard",
        exact: true,
        component: GenericDashboard,
        id: "dashboard",
        menuText: null,
        menuTextEn: null,
        menuHidden: true,
        title: "Dashboard",
        subTitle: "Datos generales de mi cuenta",
        seoTitle: "Pichichus Dashboard",
        seoDescription: "Peluqueria para mascotas en Palma de Mallorca. Servicios de peluquería para perros, gatos y conejos en Palma de Mallorca.",
        seoKeywords: "Peluqueria para mascotas en Palma de Mallorca, Peluquería para perros, gatos y conejos en Palma de Mallorca, Peluquería Canina, Estilista Canina, Cortes de pelo para perritos, Cortes de pelo para mascotas, Tienda para Mascotas, Peluquería para Mascotas, Peluqueria Felina",
        seoAuthor: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        seoDefaultAlt: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        breadcrumb: null,
        gallery: {}
    },
    {
        path: "(/mis-compras)",
        relativeUrl: "/mis-compras",
        url: "https://www.pichichus.com/mis-compras",
        exact: true,
        component: GenericMyShopCarts,
        id: "my-shop-carts",
        menuText: null,
        menuTextEn: null,
        menuHidden: true,
        title: "Mis compras",
        subTitle: "",
        seoTitle: "Pichichus: Mis Compras",
        seoDescription: "Peluqueria para mascotas en Palma de Mallorca. Servicios de peluquería para perros, gatos y conejos en Palma de Mallorca.",
        seoKeywords: "Peluqueria para mascotas en Palma de Mallorca, Peluquería para perros, gatos y conejos en Palma de Mallorca, Peluquería Canina, Estilista Canina, Cortes de pelo para perritos, Cortes de pelo para mascotas, Tienda para Mascotas, Peluquería para Mascotas, Peluqueria Felina",
        seoAuthor: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        seoDefaultAlt: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        breadcrumb: null,
        gallery: {}
    },
    {
        path: "(/plink/.*)",
        relativeUrl: "/plink",
        url: "https://www.pichichus.com/plink",
        exact: true,
        component: GenericPaymentLink,
        id: "paymentlink",
        menuText: "Link de pago",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: null,
        seoTitle: "Pichichus: Link de pago",
        seoDescription: "Peluqueria para mascotas en Palma de Mallorca. Servicios de peluquería para perros, gatos y conejos en Palma de Mallorca.",
        seoKeywords: "Peluqueria para mascotas en Palma de Mallorca, Peluquería para perros, gatos y conejos en Palma de Mallorca, Peluquería Canina, Estilista Canina, Cortes de pelo para perritos, Cortes de pelo para mascotas, Tienda para Mascotas, Peluquería para Mascotas, Peluqueria Felina",
        seoAuthor: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        seoDefaultAlt: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        embeds: [],
        gallery: {}
    },
    {
        path: "(/slink/.*)",
        relativeUrl: "/slink",
        url: "https://www.pichichus.com/slink",
        exact: true,
        component: GenericSaleLink,
        id: "salelink",
        menuText: "Link de venta",
        menuHidden: true,
        title: "",
        subTitle: "",
        breadcrumb: null,
        seoTitle: "Pichichus: Link de venta",
        seoDescription: "Peluqueria para mascotas en Palma de Mallorca. Servicios de peluquería para perros, gatos y conejos en Palma de Mallorca.",
        seoKeywords: "Peluqueria para mascotas en Palma de Mallorca, Peluquería para perros, gatos y conejos en Palma de Mallorca, Peluquería Canina, Estilista Canina, Cortes de pelo para perritos, Cortes de pelo para mascotas, Tienda para Mascotas, Peluquería para Mascotas, Peluqueria Felina",
        seoAuthor: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        seoDefaultAlt: "Pichichus | Peluquería para mascotas, Formación y Tienda especializada",
        embeds: [],
        gallery: {}
    }
];

const getPagePropByLang = ( page, lang, prop ) => {
    const langProp = `${ prop }${ lang.charAt( 0 ).toUpperCase() + lang.slice( 1 ) }`;
    if ( typeof page[ langProp ] !== "undefined" ) {
        return page[ langProp ];
    }
    return page[ prop ];
};

export const applyShopOverides = ( item ) => {
    const result = Object.assign( {}, item );
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            Object.keys( item ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( item, lang, key );
            } );
        }
    }
    return result;
};

const applyPageOverwrites = ( page ) => {
    let result = page;
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            result = { ...page, title: getPagePropByLang( page, lang, "title" ) };
            Object.keys( page ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( page, lang, key );
            } );
        }
    }
    return result;
};

export const applyNewsOverides = ( item ) => {
    const result = { ...item };
    if ( store ) {
        const state = store.getState();
        if ( typeof state.pwSession.language !== "undefined" ) {
            const lang = state.pwSession.language;
            Object.keys( item ).forEach( ( key ) => {
                result[ key ] = getPagePropByLang( item, lang, key );
            } );
        }
    }
    return result;
};

export const getPage = ( id ) => {
    let result = all.find( ( page ) => page.id === id );
    if ( !result ) {
        result = {
            path: "/",
            exact: true
        };
    } else {
        result = applyPageOverwrites( result );
    }
    return result;
};

const texts = {
    es: {
        "navbar.title": "",
        "navbar.search": "Buscar",
        "navbar.shop.services": "SERVICIOS",
        "navbar.shop.giftcards": "TARJETA REGALO",
        "navbar.shop.products": "PRODUCTOS",
        "footer.contact.text1": "¿Necesita más información?",
        "footer.contact.text2": "¡Fácil! Coméntenos brevemente su idea y le contestaremos inmediatamente.",
        "footer.contact.fullname": "Nombre Completo",
        "footer.contact.fullname.description": "Escribe tu nombre completo para poder dirigirnos a ti correctamente.",
        "footer.contact.email": "Email",
        "footer.contact.email.description": "Escribe aquí tu email para que podamos contestar a la brevedad.",
        "footer.contact.phone": "Teléfono",
        "footer.contact.phone.description": "Escribe aquí tu teléfono si prefieres que te contactemos por Whatsapp o una llamada.",
        "footer.contact.message": "Mensaje",
        "footer.contact.privacypolicy": `He leído y acepto la <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Política de privacidad</a>${ company.footerTermsConditions ? `<span> y los <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Términos y Condiciones</a></span>` : "" }`,
        "footer.contact.sendmessage": "Enviar consulta",
        "footer.contact.orremember": "O recuerde que también puede contactarnos a través de",
        "footer.contact.emailbutton": "Correo electrónico",
        "footer.contact.whatsapp": "Enviar un WhatsApp",
        "footer.contact.visitfacebook": "Visítanos en Facebook",
        "footer.contact.successmessage.text1": "Gracias por ponerte en contacto con nosotros.",
        "footer.contact.successmessage.text2": "Hemos recibido tu mensaje y te responderemos a la brevedad a",
        "footer.contact.successmessage.text3": "En {companyName} nos tomamos muy en serio las consultas de nuestros clientes y en responder profesionalmente. Muchas gracias por su paciencia.",
        "footer.copyright.text1": "Copyright 2019, Pichichus: Salón para mascotas en Palma de Mallorca",
        "footer.contactinfo.form.title": "Déjanos tu mensaje",
        "footer.contactinfo.about.title": "Nuestro salón de mascotas",
        "rgpd.footer.contact.acceptcommercial": "Acepto recibir comunicaciones comerciales",
        "rgpd.generic.validation.acceptcommercial": "Debes aceptar recibir comunicaciones comerciales para poder continuar",
        // footer contact form
        "footer.contact.promocajas.title": "Reserva una caja para tu mascota a precio promocional",
        "footer.contact.promocajas.subtitle": "Completa el siguiente formulario para conseguir tu reserva",
        "footer.contact.promocajas.successmessage.title": "Gracias por enviarme tu solicitud.",
        "footer.contact.promocajas.successmessage.text1": "He recibido tu mensaje y te responderé a la brevedad.",
        "footer.contact.promocajas.successmessage.text2": "Estaré organizando organizando las cajas a entregar, por lo que me pondré en contacto contigo para planificar la entrega. Muchas gracias",
        "footer.contact.promocajas.phone": "Teléfono",
        "footer.contact.promocajas.nombremascota": "Nombre de tu Mascota",
        "footer.contact.promocajas.mascota": "¿Perro, Gato, Conejo o Cobaya?",
        "footer.contact.promocajas.edad": "Edad",
        "footer.contact.promocajas.sexo": "Sexo",
        "footer.contact.promocajas.peso": "Peso",
        "footer.contact.promocajas.raza": "Raza",
        "footer.contact.promocajas.message": "Describe brevemente a tu mascota",
        "footer.contact.promocajas.send": "Solicitar Reserva",
        "footer.floatingvideo.sendmessage": "Envíame un mensaje",
        // news
        "news.categories": "Categorías",
        "news.viewmore": "Ver más",
        // mg
        "mg.generic.validation.empty": "Debes completar todos los datos para continuar",
        "mg.generic.validation.invalidemail": "El email ingresado no es válido",
        "mg.generic.validation.emailalreadyexists": "El email ingresado ya se encuentra en uso",
        "mg.customers.status.active": "Cliente",
        "mg.customers.status.inactive": "Desactivado",
        "mg.invoices.status.editing": "Editando",
        "mg.invoices.status.sent": "Enviada",
        "mg.invoices.status.paid": "Pagada",
        "mg.courses.status.editing": "En edición",
        "mg.courses.status.active": "Publicado",
        "mg.courses.status.inactive": "Desactivado",
        "mg.team.status.active": "Activo",
        "mg.team.status.inactive": "Desactivado",
        "mg.generic.validation.giftcard.existingcode": "El código de tarjeta ya ha sido utilizado antes. Modifique el código para continuar.",
        // signup & login
        "signup.form.title": "Completa con tus datos para crear una cuenta",
        "signup.form.subtitle": "",
        "signup.form.facebook.login.error": "Hubo un error en la autenticación a través de Facebook. Por favor, vuelve a intentarlo o completa el formulario de registro.",
        "signup.form.readandacceptpolicy": "He leído y acepto la",
        "signup.form.privacypolicy": "Política de privacidad",
        "signup.form.signupbutton": "Crear mi cuenta",
        "signup.form.backloginbutton": "Acceder a mi cuenta",
        "signup.form.separator.text": "¿Ya tienes cuenta?",
        "signup.validation.emailalreadyexists": "Ya existe una cuenta vinculada al email ingresado. Por favor, accede a tu cuenta o recupera tu clave.",
        "signup.validation.passwordstrength": "La clave debe contener al menos 6 caracteres e incluir al menos una letra y un número. Se recomienda usar símbolos y mayúsculas para mayor seguridad.",
        "signup.password.hinttext": "Mínimo 6 caracteres, con al menos una letra y un número",
        "signup.form.facebookbutton": "Continuar con Facebook",
        "signup.validation.facebook.sessionexpired": "Se ha producido un error al conectarse con Facebook, por favor, vuelva a intentarlo.",
        "signup.success.title": "Accede a tu email para confirmar tu cuenta",
        "signup.success.text": "Te hemos enviado un correo a {email} para validar tu dirección de email. Revisa tu correo y verifica tu cuenta para poder continuar.",
        "signup.login.email.notverified": "Tienes pendiente la verificación de tu cuenta de correo. Por favor, accede a tu correo y continúa la verificación de tu cuenta. Muchas gracias",
        "signup.login.invalid.credentials": "El usuario y clave ingresados son incorrectos",
        "login.form.title": "Accede a tu cuenta",
        "login.form.subtitle": "",
        "login.form.loginbutton": "Acceder a mi cuenta",
        "login.form.separator.text": "¿No tienes cuenta?",
        "login.form.signupbutton": "Crear una cuenta",
        "checkout.form.selectplan": "Seleccionar plan",
        "checkout.form.selectplan.text": "Debes seleccionar un plan para comenzar",
        "checkout.form.selectplan.selector": "Seleccionar ...",
        "checkout.validation.useralreadysubscribed": "Ya te encuentras dado de alta en este Plan.",
        "checkout.form.title": "",
        "generic.validation.close": "cerrar",
        "generic.validation.empty": "Complete todos los datos del formulario para poder continuar. Muchas gracias.",
        "generic.validation.recaptcha": "Marque la casilla \"No soy un robot\" para poder continuar.",
        "generic.validation.privacypolicy": "Debe aceptar la Política de Privacidad para continuar.",
        "generic.validation.invalidemail": "Por favor compruebe que su dirección de email es correcta. Al parecer no tiene un formato válido.",
        "generic.validation.passwordmismatch": "La confirmación de la clave no coincide. Compruebe que las claves coinciden exactamente.",
        "generic.server.error": "Hubo un error en la conexión con el servidor.",
        "signup.emailverified.success": "¡Enhorabuena! ¡Ya puedes utilizar tu cuenta!",
        "signup.navbar.login": "ACCEDER",
        "signup.navbar.signup": "REGISTRARME",
        "signup.navbar.dashboard": "Mi cuenta",
        "signup.navbar.logout": "Cerrar",
        "checkout.currentproduct.content.title": "Datos de mi plan actual",
        "checkout.currentproduct.content.subtitle": "¡Enhorabuena! Actualmente te encuentras suscrito a nuestro plan",
        "checkout.currentproduct.yourplan": "Tu plan actual",
        "checkout.currentproduct.yourplan.text": "Actualmente te encuentras suscrito al siguiente plan:",
        "signup.form.signuppassword.title": "Ingresa una clave",
        "signup.form.passwordconfirmation": "Confirmar Clave",
        "signup.form.password": "Clave",
        "signup.resetpassword.title": "Crea una nueva clave de acceso",
        "signup.resetpassword.subtitle": "Escribe tu nueva clave de acceso",
        "signup.form.newpasswordconfirmation": "Confirmar nueva clave",
        "signup.form.newpassword": "Nueva clave",
        "signup.form.newpassword.save": "Guardar cambios",
        "signup.forgotpassword.title": "¿Has olvidado tu contraseña?",
        "signup.forgotpassword.subtitle": "Escribe el email de tu cuenta para recuperarla",
        "signup.forgotpassword.emailnotexists": "El email que has ingresado no pertenece a una cuenta de usuario.",
        "signup.forgotpassword.pleasecheckemail": "Por favor, revisa tu correo electrónico. Te hemos enviado un enlace con el que podrás crear una nueva clave de acceso.",
        "signup.forgotpassword.processbutton": "Recuperar clave",
        "signup.login.newpasswordsaved": "Tu nueva clave ha sido guardada. Ingresa tu email para acceder.",
        "signup.form.forgotpasswordlink": "¿Has olvidado tu contraseña?",
        "signup.emailverified.textclicktocontinue": "Haz clic en el siguiente enlace para continuar",
        "signup.emailverified.continue": "Continuar",
        // shop
        "shop.validation.empty": "Complete todos los datos del formulario para poder continuar. Muchas gracias.",
        "shop.validation.recaptcha": "Marque la casilla \"No soy un robot\" para poder continuar.",
        "shop.validation.privacypolicyandterms": "Debe aceptar la Política de Privacidad y la Política de Contratación para continuar.",
        "shop.validation.invalidemail": "Por favor compruebe que su dirección de email es correcta. Al parecer no tiene un formato válido.",
        "shop.validation.emptycart": "Su cesta se encuentra vacío o su sesión ha expirado.",
        "shop.validation.notexistingcartitem": "No se ha podido procesar su cesta porque uno de los productos seleccionados ha dejado de estar disponible. Por favor, vuelva a intentarlo más tarde. Muchas gracias.",
        "shop.validation.modifiedpricecartitem": "No se ha podido procesar su cesta porque el precio de uno de los productos seleccionados ha sido modificado. Por favor, vuelva a intentarlo más tarde. Muchas gracias.",
        "shop.validation.expiredcartitem": "No se ha podido procesar su cesta porque su sesión ha expirado",
        "shop.validation.unavailableitem": "No se ha podido procesar su cesta porque su sesión ha expirado y uno de los items ya no se encuentra disponible.",
        "shop.validation.invalidrequest": "No se ha podido procesar su cesta porque su sesión ha expirado y su petición es inválida.",
        "shop.validation.maxquantityperpurchase": "No se ha prodido procesar su cesta porque uno de los items ya no se encuentra disponible y se ha excedido la cantidad posible de compra.",
        "shop.validation.genericservererror": "No se ha podido procesar su cesta debido a un error de comunicación con el servidor. Por favor, vuelva a intentarlo más tarde. Muchas gracias.",
        "shop.validation.requiredaccount": "Para continuar con la compra es necesario que crees tu cuenta de usuario o accedas en caso de que ya tengas una. ¡Es muy rápido y fácil!",
        "shop.validation.requiredemailverified": "Para continuar con la compra es necesario que accedas a tu correo electrónico y confirmes tu cuenta con el enlace que te hemos enviado.",
        "shop.validation.close": "Cerrar",
        "shop.checkout.success.title": "Gracias por su compra",
        "shop.checkout.success.message.title": "¡Tu compra ha sido procesada exitosamente!",
        "shop.checkout.success.message.paragraph": "Te hemos enviado un email con toda la información acerca de tu compra. Revisa tu correo y recuerda que si has hecho la compra accediendo con una cuenta de usuario, puedes acceder desde \"Mi cuenta\" para más información.",
        "shop.checkout.success.message.paragraph2": "Muchas gracias",
        "shop.item.code": "Código",
        "shop.item.unitprice": "Precio unitario",
        "shop.item.totalprice": "Precio total",
        "shop.checkout.finalstep": "Pasar por caja",
        "shop.checkout.backcart": "Volver a la cesta",
        "shop.checkout.customerformtitle": "Datos de cliente",
        "shop.checkout.customerformtext": "Complete el siguiente formulario con sus datos",
        "shop.checkout.fullname": "Nombre Completo",
        "shop.checkout.email": "Email",
        "shop.checkout.mobile": "Teléfono Móvil",
        "shop.checkout.address": "Dirección",
        "shop.checkout.comments": "Comentarios",
        "shop.checkout.accepttermsconditions": `He leído y acepto la <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Política de privacidad</a>${ company.footerTermsConditions ? `<span> y la <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Política de Contratación</a></span>` : "" }`,
        "shop.checkout.reviewpurchase": "Revisa el detalle de la compra",
        "shop.checkout.reviewpurchase.subtotal": "Subtotal",
        "shop.checkout.included": "incluído",
        "shop.checkout.reviewpurchase.total": "Total",
        "shop.checkout.paymentmethod": "Forma de pago",
        "shop.checkout.banktransfer": "Transferencia Bancaria",
        "shop.checkout.banktransfertext": "Al realizar una compra por transferencia bancaria, recibirás un email con tu código de compra con el que podrás acceder a consultar el estado y los datos para realizar la transferencia bancaria",
        "shop.checkout.totalfinal": "Total",
        "shop.checkout.processpurchasebutton": "Procesar Compra",
        "shop.checkout.processpurchasebutton.card": "Pagar con tarjeta",
        "shop.checkout.processpaymentlink": "Pagar con tarjeta",
        "shop.checkout.processpaymentlink.bizum": "Pagar con Bizum",
        "shop.checkout.paybycard": "Pagar con tarjeta",
        "shop.purchase.codenotfound": "El código de compra ingresado y el email no son correctos.",
        "shop.purchase.details.title": "Detalle de compra",
        "shop.purchase.details.subtitle": "Completa la siguiente información para consultar los detalles de tu compra",
        "shop.purchase.details.button": "Consultar detalle",
        "shop.purchase.details.field.code.label": "Ingresa el código de tu compra",
        "shop.purchase.details.field.email.label": "Email con el que has hecho tu compra",
        "shop.purchase.details.customertitle": "Datos de cliente",
        "shop.purchase.details.itemstitle": "Detalle de la compra",
        "shop.purchase.details.statustitle": "Estado de su compra",
        "shop.purchase.details.paymentmethod": "Forma de pago",
        "shop.purchase.details.status.pagantis.started.title": "El usuario no ha finalizado el pago a través de Pagantis",
        "shop.purchase.details.status.pagantis.started.text": "En este intento, el usuario ha iniciado el carrito, pero no ha finalizado con el proceso de pago en Pagantis",
        "shop.purchase.details.status.pagantis.started.footertext": "En caso de no finalizar el pago a través de Pagantis en el plazo de 48 hs anularemos este carrito",
        "shop.purchase.details.status.banktransfer.pending.title": "Pago por transferencia pendiente de confirmar",
        "shop.purchase.details.status.banktransfer.pending.text": "Para completar su compra debe realizar la siguiente transferencia bancaria en un plazo de 24hs:",
        "shop.purchase.details.status.banktransfer.pending.concept": "Ingrese su Email como concepto de transferencia",
        "shop.purchase.details.status.confirmed.title": "Su compra ha sido confirmada por nuestro equipo",
        "shop.purchase.details.status.confirmed.text": `Hemos verificado el pago y su compra se encuentra confirmada. Muchas gracias por confiar en ${ company.name }`,
        "shop.purchase.details.status.banktransfer.pending.footertext": "En caso de haber realizado la transferencia, puedes enviarnos el comprobante para agilizar el proceso de verificación.",
        "shop.purchase.status.started": "Pendiente",
        "shop.purchase.status.confirmed": "Confirmada",
        "shop.purchase.status.canceled": "Cancelada",
        "shop.purchase.paymentmethod.banktransfer": "Transferencia Bancaria",
        "shop.purchase.paymentmethod.pagantis": "Pagantis - Financiación",
        "shop.purchase.paymentmethod.stripe": "Pago con tarjeta vía Stripe",
        "shop.purchase.details.status.canceled.title": "Su compra ha sido cancelada",
        "shop.purchase.details.status.canceled.text": "Nuestro equipo ha marcado esta compra como cancelada. Los motivos pueden ser varios, como la falta del pago correspondiente o debido a un error por parte del usuario.",
        "shop.purchase.confirmed.title": `Su compra ha sido confirmada por nuestro equipo de ${ company.name }`,
        "shop.purchase.confirmed.subtitle": "Por favor, conserve el comprobante de compra adjunto.",
        "shop.purchase.confirmed.details.button.p": "Recuerde que también puede consultar todos los detalles de su compra accediendo a su cuenta:",
        "shop.purchase.confirmed.codetext": "El código de referencia de su compra es",
        "shop.purchase.button.view.details": "Ver detalle",
        "shop.purchase.thanks.paylater.title": "Muchas gracias por su reserva",
        "shop.purchase.email.started.paylater.subject": `Muchas gracias por su reserva en ${ company.name }`,
        "shop.purchase.details.button.p": "Puede acceder al detalle de su compra haciendo clic en el siguiente enlace:",
        "shop.purchase.thanks.title": "Muchas gracias por su compra",
        "shop.purchase.thanks.subtitle": "Por favor, conserve la siguiente información",
        "shop.purchase.email.confirmed.subject": `Comprobante de compra de ${ company.name }`,
        "shop.purchase.email.started.subject": `Muchas gracias por su compra en ${ company.name }`,
        "shop.purchase.order.pdf.title": `Comprobante de compra de ${ company.name }`,
        "shop.checkout.pagantis": "Pago en Cuotas",
        "shop.checkout.pagantistitle": "Simulador de cuota",
        "shop.checkout.pagantistext": "Puedes usar el siguiente simulador seleccionando el valor de cuota que desea pagar:",
        "shop.checkout.stripe": "Pago con tarjeta vía Stripe",
        "shop.checkout.stripetitle": "Pago con tarjeta",
        "shop.checkout.stripetext": "Pago con tarjeta de forma segura a través de Stripe",
        "shop.shorttext.viewmore": "...ver más",
        "shop.item.mycart.view": "Ver mi cesta",
        "shop.item.mycart.already": "Añadir a la cesta",
        "shop.item.mycart.add": "Añadir a la cesta",
        "shop.items.loadmore": "Cargar más productos",
        "shop.continue.buying": "Seguir comprando",
        "shop.navbar.top.mycart": "Mi cesta",
        "shop.search.noresults": "No se han encontrado resultados para",
        "shop.cart.unitprice": "Precio Unitario",
        "shop.cart.productcode": "Código",
        "shop.cart.totalprice": "Precio Total",
        "shop.cart.removefromcart": "Quitar de la cesta",
        "shop.cart.contenttitle": "Contenido de la cesta",
        "shop.cart.emptybutton": "Vaciar cesta",
        "shop.cart.yourcartisempty": "Su cesta se encuentra vacía",
        "shop.cart.subtotal": "Subtotal",
        "shop.cart.shippingprice": "Gastos de envío",
        "shop.cart.missingforfreeshipping": "Faltan {freeShippingMissing} para envío gratuito",
        "shop.cart.total": "Total",
        "shop.cart.quantity": "Cantidad",
        "shop.cart.markasgift": "Marcar como regalo",
        "shop.cart.unmarkasgift": "No marcar como regalo",
        "shop.cart.giftcardservices.message": "Los items que marques como regalo serán incluídos en una tarjeta que podrás compartir. Sólo puedes crear una tarjeta regalo con servicios por compra.",
        "shop.checkout.useraccount": "Cuenta de usuario",
        "shop.checkout.alreadyloggedin": "Ya te encuentras registrado y has accedido con tu cuenta",
        "shop.checkout.pleaseverifyaccount": "Tu cuenta de correo no ha sido verificada mediante el enlace que te hemos enviado.<br />Por favor, <b>accede a tu correo y completa la verificación</b>.</p><p><b>¿Ya has verificado tu correo?</b>",
        "shop.checkout.clickhere": "Clic aquí",
        "shop.checkout.alreadyaccountquestion": "¿Tienes cuenta de usuario?",
        "shop.checkout.gettingdata": "Obteniendo datos",
        "shop.checkout.accessyouraccountsuggestion": "Accede si ya tienes una cuenta o crea una nueva para mantener el registro de tus compras",
        "shop.purchase.paymentmethod.stripe.description": "Paga de forma segura mediante Stripe, la plataforma de pagos de confianza de millones de empresas y usuarios",
        "shop.checkout.city": "Ciudad",
        "shop.checkout.state": "Provincia",
        "shop.checkout.postalcode": "Código Postal",
        "shop.checkout.spain": "España",
        "shop.checkout.country": "País (Sólo España)",
        "shop.checkout.onlyspain": "Sólo España",
        "shop.validation.invalidpostalcode": "El código postal no es válido",
        "shop.checkout.login": "Acceder a mi cuenta",
        "shop.checkout.signup": "Crear cuenta",
        "shop.checkout.redsys": "Pago vía Redsys",
        "shop.purchase.paymentmethod.redsys": "Pago vía Redsys",
        "shop.purchase.paymentmethod.redsys.description": "Paga de forma segura a través de Redsys: plataforma de pagos líder en España",
        "shop.item.giftcardservices.title": "Tarjeta Regalo con Tratamientos",
        "shop.item.giftcardservices.description": "¿Quieres regalar una tarjeta con servicios incluídos? ¡Es muy fácil! Simplemente añade a la cesta los servicios que quieras regalar y luego, en tu cesta, márcalos como regalo y te enviaremos una tarjeta que podrás compartir.",
        "shop.item.giftcardamount.title": "Tarjeta Regalo con Saldo",
        "shop.item.giftcardamount.description": "¿Quieres regalar una tarjeta con saldo? ¡Es muy fácil! Añade este ítem a tu cesta y desde allí podrás modificar el saldo para que luego te enviemos una tarjeta que podrás compartir.",
        "shop.cart.prepaymentservices.title": "¿Prefieres pagar sólo la reserva de los servicios?",
        "shop.cart.prepaymentservices.active.title": "Has seleccionado pagar sólo las reservas de los servicios que no sean de regalo",
        "shop.cart.prepaymentservices.description": "Opcionalmente, puedes pagar sólo una reserva de {prepaymentServicePrice} por servicios que no sean para regalo",
        "shop.cart.prepaymentservices.active.description": "Pagarás {prepaymentServicePrice} por cada tratamiento que hayas seleccionado en concepto de reserva. Las tarjetas regalo, servicios marcados para regalo y productos, no están incluídos y se pagan en su totalidad. El pago restante, podrás hacerlo en nuestro centro.",
        "shop.validation.promocode.empty": "Ingresa un código de promoción para poder continuar",
        "shop.validation.promocode.notfound": "El código ingresado no es válido",
        "shop.validation.promocode.notactive": "El código ingresado no pertenece a ninguna promo activa",
        "shop.validation.promocode.totalusereached": "El código ingresado ha llegado a su límite de uso y se encuentra inactivo",
        "shop.validation.promocode.notvalidforcustomer": "El código ingresado no es válido para esta cuenta",
        "shop.item.resellerprice": "Precio PRO (IVA incluído)",
        "shop.checkout.paylater.lasttext": "A pagar el total el día de tu cita.",
        "shop.checkout.paybysavedcard": "Pagar con tarjeta guardada",
        "shop.checkout.paybyanothercard": "Pagar con otra tarjeta",
        // locations
        "locations.search.noresults": "No se han encontrado resultados para",
        "dashboard.locations.title": "Tus lugares en el mapa",
        // dashboard
        "dashboard.bienvenida.title": "Bienvenid@ a tu cuenta",
        "dashboard.bienvenida.text": "Desde tu acceso podrás acceder de forma directa a toda la información de tus pedidos, servicios contratados como también a nuestras novedades exclusivas para clientes.",
        "dashboard.questions.title": "¿Preguntas?",
        "dashboard.questions.text": "No dudes en enviarnos un y te responderemos a la brevedad.",
        "dashboard.myplan.title": "Mi Plan Actual",
        "dashboard.myplan.text": "",
        "dashboard.myplan.text.noproducts": "Actualmente no tienes contratado ningún plan.",
        "dashboard.myplan.bajalink": "Solicitar baja",
        "generic.server.error.changedonserver": "Se han realizado cambios en el item. Por favor, refresque el navegador para volver a intentar."
    },
    en: {
        "navbar.title": "",
        "footer.contact.text1": "Any further questions?",
        "footer.contact.text2": "Please do not hesitate to contact us, tell us about your business or project and We will reply as soon as possible.",
        "footer.contact.fullname": "Full name",
        "footer.contact.fullname.description": "Please enter your full name",
        "footer.contact.email": "Email",
        "footer.contact.email.description": "Enter your email to reply as soon as possible.",
        "footer.contact.phone": "Phone",
        "footer.contact.phone.description": "Enter your phone and we can contact you via Whatsapp or a call.",
        "footer.contact.message": "Message",
        "footer.contact.privacypolicy": `I have read and accept the <a href="${ getPage( "privacy-policy" ).relativeUrl }" target="_blank" rel="noopener noreferrer">Privacy Policy</a>${ company.footerTermsConditions ? `<span> and the <a href=${ getPage( "terms-conditions" ).relativeUrl } target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span>` : "" }`,
        "footer.contact.sendmessage": "Send message",
        "footer.contact.orremember": "Or remember you can also send us a message via",
        "footer.contact.emailbutton": "Email",
        "footer.contact.whatsapp": "Send WhatsApp",
        "footer.contact.visitfacebook": "Visit us on Facebook",
        "footer.contact.successmessage.text1": "Thank you to contact us",
        "footer.contact.successmessage.text2": "Your message has been successfully sent and we´ll reply you as soon as possible.",
        "footer.contact.successmessage.text3": "At {companyName} we work hard to reply our customers messages in a professional way. Thank you for your patience. ",
        "footer.copyright.text1": "Copyright 2019, Pichichus: Salón para mascotas en Palma de Mallorca",
        // footer contact form
        "footer.contact.promocajas.title": "Reserva una caja para tu mascota a precio promocional",
        "footer.contact.promocajas.subtitle": "Completa el siguiente formulario para conseguir tu reserva",
        "footer.contact.promocajas.successmessage.title": "Gracias por enviarme tu solicitud.",
        "footer.contact.promocajas.successmessage.text1": "He recibido tu mensaje y te responderé a la brevedad.",
        "footer.contact.promocajas.successmessage.text2": "Estaré organizando organizando las cajas a entregar, por lo que me pondré en contacto contigo para planificar la entrega. Muchas gracias",
        "footer.contact.promocajas.phone": "Teléfono",
        "footer.contact.promocajas.nombremascota": "Nombre de tu Mascota",
        "footer.contact.promocajas.mascota": "¿Perro, Gato, Conejo o Cobaya?",
        "footer.contact.promocajas.edad": "Edad",
        "footer.contact.promocajas.sexo": "Sexo",
        "footer.contact.promocajas.peso": "Peso",
        "footer.contact.promocajas.raza": "Raza",
        "footer.contact.promocajas.message": "Describe brevemente a tu mascota",
        "footer.contact.promocajas.send": "Solicitar Reserva",
        // news
        "news.categories": "Categorías",
        "news.viewmore": "Ver más"
    }
};

export const defaultLanguage = "es";
export const availableLanguages = [ "es" ];

// GENERIC
export const getPageByPath = ( path ) => {
    let result = all.find( ( page ) => {
        if ( page.path === path ) {
            return page;
        }
        if ( matchPath( path, page ) ) {
            return page;
        }
        return null;
    } );

    if ( !result ) {
        result = {
            path: "/",
            exact: true
        };
    } else {
        result = applyPageOverwrites( result );
    }
    return result;
};

export const manager = {};
manager.getRelativeUrl = ( relative ) => `${ company.managerURLRelative }/${ relative }`;

export const text = ( lang, id, replacements ) => {
    let result = "";
    if ( texts[ lang ] && texts[ lang ][ id ] ) {
        result = texts[ lang ][ id ];
    }
    if ( typeof replacements !== "undefined" ) {
        Object.keys( replacements ).forEach( key => {
            result = result.replace( new RegExp( `{${ key }}`, "g" ), replacements[ key ] );
        } );
    }
    return result;
};

export const getLanguageName = ( lang ) => {
    switch ( lang ) {
        case "en":
            return "English";
        case "es":
            return "Español";
        default:
            return "Español";
    }
};

export const getLangByUrlPath = ( path ) => {
    let result = defaultLanguage;
    availableLanguages.forEach( ( lang ) => {
        if ( path.indexOf( `/${ lang }/` ) > -1 ) {
            result = lang;
        }
    } );
    return result;
};

export const contactForms = [
    {
        id: "promocajas",
        formName: "Promo Cajas",
        relativeUrl: `${ getPage( "news" ).relativeUrl }/promos/cajas-fiestas-2019`,
        titleLanguageKey: "footer.contact.promocajas.title",
        subTitleLanguageKey: "footer.contact.promocajas.subtitle",
        successTitleLanguageKey: "footer.contact.promocajas.successmessage.title",
        successText1LanguageKey: "footer.contact.promocajas.successmessage.text1",
        successText2LanguageKey: "footer.contact.promocajas.successmessage.text2",
        successSendLanguageKey: "footer.contact.promocajas.send",
        emailSubject: "Promo Cajas 2019 - Nuevo Contacto Web",
        fields: [
            { id: "contactName", labelLanguageKey: "footer.contact.fullname", mandatory: true },
            { id: "contactEmail", labelLanguageKey: "footer.contact.email", mandatory: true },
            { id: "contactPhone", labelLanguageKey: "footer.contact.promocajas.phone", mandatory: true },
            { id: "customNombreMascota", labelLanguageKey: "footer.contact.promocajas.nombremascota", descriptionLanguageKey: null },
            { id: "customMascota", labelLanguageKey: "footer.contact.promocajas.mascota", descriptionLanguageKey: null },
            { id: "customEdad", labelLanguageKey: "footer.contact.promocajas.edad", descriptionLanguageKey: null },
            { id: "customSexo", labelLanguageKey: "footer.contact.promocajas.sexo", descriptionLanguageKey: null },
            { id: "customPeso", labelLanguageKey: "footer.contact.promocajas.peso", descriptionLanguageKey: null },
            { id: "customRaza", labelLanguageKey: "footer.contact.promocajas.raza", descriptionLanguageKey: null },
            {
                id: "contactMessage",
                labelLanguageKey: "footer.contact.promocajas.message",
                type: "textarea",
                rows: 3,
                mandatory: true
            }
        ]
    }
];
export const emailTemplates = {
    theme: {
        logoBackgroundColor: "white",
        logoUrl: `${ company.baseURL }/static/logo-rectangle.png`,
        thanksBackgroundColor: "#F8F8F8",
        buttonBackgroundColor: "#d1e7dd",
        buttonTextColor: "black",
        purchaseDetailsUrl: `${ company.baseURL }/shop/purchase`,
        defaultFont: "Arial",
        defaultFontColor: "#333333",
        purchaseThanksTitle: "Muchas gracias por su compra",
        purchaseThanksSubTitle: "Por favor, conserve la siguiente información.",
        footerText: `Para cualquier información no dude en contactarnos a través de nuestra web ${ company.baseURL }`
    }
};

export const floatingVideos = [];
export const gtag = null;
export const floatingActionButtons = null;
